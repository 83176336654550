export default {
  localStoragePrefix: "Reelz",
  auth0: {
    domain: "reelz-admin.auth0.com",
    clientID: "ERFDZS6T-11VKi-lrPzB8zn6eqCgVOdL"
  },
  cognito: {
    region: 'us-east-1',
    UserPoolId: process.env.REACT_APP_USER_POOL_ID,
    ClientId: process.env.REACT_APP_CLIENT_ID,
    columns: ['ID', 'REF ID', 'VIDEO LENGTH', 'PROCESSING TIME', 'STATUS']
  },
  apiUrl: process.env.REACT_APP_API_URL,
  RETRY_TIMEOUT: 2000,
  FETCH_INTERVAL: 30000,
  localStorageKeys: {
    JOB: "job",
    NUM_ROWS: "numRows",
    COLUMNS: "columns"
  },
  bannerBGColor: "#263238",
  bannerImage: "https://s13686.pcdn.co/wp-content/themes/reelz/images/Reelz_logo_blue.png",
  videoImages: {
    poster: process.env.REACT_APP_POSTER_URL,
    thumbnail: process.env.REACT_APP_THUMBNAIL_URL
  },
  statusIcons: {
    complete: {
      color: "#a5c13f", 
      iconId: "fas fa-check"
    },
    started: {
      color: "#409ca9", 
      iconId: "fas fa-hourglass-half"
    },
    pending: {
      color: "#1ab9d5", 
      iconId: "fas fa-hourglass-start"
    },
    failed: {
      color: "#f67e33", 
      iconId: "fas fa-ban"
    }
  }
};
