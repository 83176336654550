import config from "../config";

export default class Video {
  _props;
  name;
  status;
  percentage;
  contentId;
  referenceId;
  batchId;
  timeStarted;
  timeEnded;
  videoLength;
  processingTime;
  showId;
  renditionData;
  uniqueAssetId;
  customFields;

  constructor(props) {
    const now = new Date();
    this._props = props;
    this.name = props.video_data.name;
    this.status = props.status;
    this.percentage = props.percentage;
    this.contentId = props.video_dataAll.id;
    this.referenceId = props.video_dataAll.reference_id;
    this.batchId = props.batch_id;
    this.timeStarted = (props.created_at && props.created_at.$date) ? this.toDate(props.created_at.$date) : null;
    this.timeEnded = (props.completed_at && props.completed_at.$date) ? this.toDate(props.completed_at.$date) : null;
    this.videoLength = this.getDuration(props.video_dataAll.duration);
    this.customFields = props.video_data.custom_fields;
    this.processingTime = (props.completed_at && props.completed_at.$date) ? (props.completed_at.$date - props.created_at.$date) :
                           (now.getTime() - props.created_at.$date);
    this.processingTime = this.status === "failed" ? "n/a" : this.getDuration(this.processingTime);
    this.showId = this.customFields ? this.customFields.show : "";
    this.uniqueAssetId = this.customFields ? this.customFields.assetid : "";
    this.renditionData = props.renditionData
  }

  // Timestamp to date string
  toDate(ts) {
    let date = new Date(ts)
    let day = date.getDate()
    let month = date.getMonth() + 1
    let hour = date.getHours()
    let min = date.getMinutes()

    function pad(n, z) {
      z = z || 2;
      return ('00' + n).slice(-z);
    }

    let result = month + '/' + day + ' - ' + pad(hour, null) + ':' + pad(min, null)
    return result
  }
  // Milliseconds to HH:mm:ss
  getDuration(s) {
    if (s === undefined) {
      return 'n/a'
    }
    function pad(n, z) {
      z = z || 2;
      return ('00' + n).slice(-z);
    }

    let ms = s % 1000;
    s = (s - ms) / 1000;
    let secs = s % 60;
    s = (s - secs) / 60;
    let mins = s % 60;
    let hrs = (s - mins) / 60;

    return pad(hrs, null) + ':' + pad(mins, null) + ':' + pad(secs, null)
  }

  getVideo() {
    return this;
  }
  
  getTextTracksString(textTracks) {
    if (!textTracks) return "";
    
    return textTracks.map((textTrack, i) => {
      return textTrack.label;
    }).join(", ");
  }
  
  getVideoPanelData() {
    return {
      title: {
        label: "Title",
        value: this.name
      },
      contentId: {
        label: "Content ID",
        value: this.contentId
      },
      referenceId: {
        label: "Reference ID",
        value: this.referenceId
      },
      batchId: {
        label: "Batch ID",
        value: this.batchId
      },
      timeStarted: {
        label: "Time Started",
        value: this.timeStarted
      },
      timeEnded: {
        label: "Time Ended",
        value: this.timeEnded
      },
      caption: {
        label: "Caption",
        value: this.getTextTracksString(this._props.video_data.text_tracks)
      },
      error: {
        label: "Error",
        value: this._props.errorMsg
      }
    }
  }
  
  getShowData() {
    return {
      showId: {
        label: "Show ID",
        value: this.showId
      },
      showTitle: {
        label: "Show Title",
        value: this.customFields ? this.customFields.showtitle : ""
      },
      showDescription: {
        label: "Show Description",
        value: this.customFields ? this.customFields.showdescription : ""
      }
    }
  }
  
  getEpisodeData() {
    let language = this.customFields ? this.customFields.language : ""
    return {
      episodeTitle: {
        label: "Episode Title",
        value: this.name
      },
      uniqueAssetId: {
        label: "Unique Asset ID",
        value: this.uniqueAssetId
      },
      language: {
        label: "Language",
        value: language ? language.toUpperCase() : ""
      },
      seasonId: {
        label: "Season ID",
        value: this.customFields ? this.customFields.season : ""
      },
      episodeId: {
        label: "Episode ID",
        value: this.customFields ? this.customFields.episode : ""
      },
      synopses: {
        label: "Synopses",
        value: this._props.video_dataAll.description
      },
      genre: {
        label: "Genre",
        value: this.customFields ? this.customFields.genre : ""
      },
      rating: {
        label: "Rating",
        value: this.customFields ? this.customFields.tvratings : ""
      },
      year: {
        label: "Year",
        value: this.customFields ? this.customFields.year : ""
      },
      windowStart: {
        label: "Window Start",
        value: this._props.video_dataAll.schedule ? this.toDate(this._props.video_dataAll.schedule.starts_at) : ""
      },
      windowEnd: {
        label: "Window End",
        value: this._props.video_dataAll.schedule ? this.toDate(this._props.video_dataAll.schedule.ends_at) : ""
      },
      runTime: {
        label: "Run Time",
        value: this.videoLength
      }
    }
  }
  
  getImages() {
    let images = config.videoImages;
    if (!images) return {
      poster: {
        label: "Poster",
        value: ""
      },
      thumbnail: {
        label: "Thumbnail",
        value: ""
      }
    }
    
    return {
      poster: {
        label: "Poster",
        value: images.poster ? images.poster.replace("{id}", "ref:" + this.referenceId) : ""
      },
      thumbnail: {
        label: "Thumbnail",
        value: images.thumbnail ? images.thumbnail.replace("{id}", "ref:" + this.referenceId) : ""
      }
    }
  }
  
  getRenditionDimension(rendition) {
    let dimension = "";
    switch (rendition.media_type) {
        case "audio":
          dimension = "-- @ " + rendition.audio_bitrate + " kbps"
          break;
        case "digital_master":
          dimension = rendition.width + " x " + rendition.height + " @ " + rendition.audio_bitrate + " kbps"
          break;
        case "video":
          if (rendition.width) {
            dimension += rendition.width + " x "
          } else {
            dimension = "-- x "
          }
          dimension += rendition.height + " @ " + rendition.video_bitrate + " kbps"
          break;
        case "progressive":
          if (rendition.width) {
            dimension += rendition.width + " x "
          } else {
            dimension = "-- x "
          }
          dimension += rendition.height + " @ " + rendition.video_bitrate + " kbps"
          break;
        case "image":
          dimension = rendition.width + " x " + rendition.height + " @ --"
          break;
      }
    return dimension;
  }
  
  getRenditionFormat(rendition) {
    let format = "";
    switch (rendition.media_type) {
        case "audio":
          format = rendition.audio_codec
          break;
        case "digital_master":
          format = "N/A"
          break;
        case "video":
          format = rendition.video_codec
          break;
        case "progressive":
          format = rendition.video_codec
          break;
        case "image":
          format = rendition.label
          break;
      }
    return format
  }
  
  getRenditions() {
    return this.renditionData.map((rendition, i) => {
      return {
        dimension: {
          label: "Dimensions",
          value: this.getRenditionDimension(rendition)
        },
        format: {
          label: "Format",
          value: this.getRenditionFormat(rendition)
        }
      }
    })
  }
}