import React from "react"
import { withRouter } from "react-router";
import { Alert, Input, Button, Panel } from "@brightcove/studio-components";
import "../styles/resetconfirm.scss";

class ResetConfirm extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      code: "",
      password: "",
      success: false
    };
  }
  
  onChangeFn = (field) => {
    return (value) => {
      this.setState({ [field]: value });
    }
  }

  onSubmit = () => {
    const { authenticator, history } = this.props;
    authenticator.confirmPassword(this.state.email, this.state.code, this.state.password, (err, result) => {
      if (err) {
        console.error(err);
        this.setState({ 
          success: false,
          error: err.message
        });
      } else {
        this.setState({ 
          success: true,
          error: null 
        });
        history.push("/");
      }
    })
  }

  render() {
    const {email, password, code, error, success} = this.state;
    let canSubmit = ((email && email.length > 0) && (code && code.length > 0) && (password && password.length > 0));
    
    return (
      <React.Fragment>
        <Panel className="resetconfirm-panel">
          <div className="resetconfirm-title">Confirm Password Reset</div>
          <Alert className="resetconfirm-error" block type={ success ? "info" : "error"} 
            style={(success || error) ? {display:"block"} : {display:"none"}}>
            { success ? "Your account is confirmed!" : (error ? error : "Something's wrong")}
          </Alert>
          <Input className="resetconfirm-field" label="Email" placeholder="Email" value={email} onChange={this.onChangeFn("email")} required/>
          <Input className="resetconfirm-field" label="Confirmation Code" placeholder="Confirmation Code" value={code} onChange={this.onChangeFn("code")} required/>
          <Input className="resetconfirm-field" label="Password" placeholder="Password" value={password} type="password" onChange={this.onChangeFn("password")} required/>
          <Button className="resetconfirm-submit" type="submit" text="Confirm Reset" onClick={this.onSubmit} disabled={!canSubmit}/>
        </Panel>
      </React.Fragment>
    )
  }
}

export default withRouter(ResetConfirm)
