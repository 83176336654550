import React from "react";
import { Panel, Definition, Thumbnail, Table, TableHead, TableRow, TableBody, TableCell } from "@brightcove/studio-components";
import "./DataPanel.scss";
import _ from "lodash";

class DataPanel extends React.Component<any, any> {
  render() {
    const { title, data, type } = this.props;
    
    switch (type) {
        case "Image":
          return (
            <div className="data-panel">
              <Definition className="data-panel-title" key={title} label="" value={title} />
              {_.map(data, (entry, key) => {
                if (!entry.value) return <Definition key={key} label={entry.label} 
                value={<Thumbnail className="data-panel-image" />} />
                
                return <Definition key={key} label={entry.label} 
                value={<Thumbnail className="data-panel-image" 
                style={entry.label === "Poster" ? {height: "auto", width: "100%"} : {height: 90, width: 160}}
                src={entry.value} />} />
              })}
            </div>
          )
          break;
        case "Rendition":
          return (
            <div className="data-panel transparent-border">
              <Definition className="data-panel-title" key={title} label="" value={title} />
              <Table className="data-panel-table">
                <TableHead>
                  <TableRow>
                    <TableCell key="dimension" header>Dimension</TableCell>
                    <TableCell key="format" header>Format</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {_.map(data, (entry, key) => {
                    return (
                      <TableRow key={key}>
                        <TableCell key={key + entry.dimension.label}>
                          {entry.dimension.value}
                        </TableCell>
                        <TableCell key={key + entry.format.label} className="cell-highlight">
                          {entry.format.value}
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </div>
          )
          break;
        default:
          return (
            <div className="data-panel">
              <Definition className="data-panel-title" key={title} label="" value={title} />
              {_.map(data, (entry, key) => {
                return <Definition key={key} label={entry.label} value={entry.value ? entry.value : "---"} />
              })}
            </div>
          )
          break;
      }
  }
};

export default DataPanel
