import React from "react"
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"
import CognitoAuthenticator from "./auth/Auth-Cognito";
import Dashboard from "./screens/Dashboard";
import Login from "./screens/Login";
import Header from "./components/Header/Header";
import Jobs from "./screens/Jobs";
import AddUser from "./screens/AddUser";
import ResetPassword from "./screens/ResetPassword";
import Confirm from "./screens/Confirm";
import ResetConfirm from "./screens/ResetConfirm";
import AuthenticationSwitch from "./auth/AuthenticationSwitch";
import Authenticator from "./auth/Authenticator";
import config from "./config";
import storage from "./utils/storage";
import { initialize } from '@brightcove/studio-components'
import { Provider as ReduxProvider } from 'react-redux'

initialize();

const auth: Authenticator = new CognitoAuthenticator();

class App extends React.Component {
  state = {
    isAuthenticated: false,
    user: null
  }

  onSignIn = async (isAuthenticated) => {
    let user = await auth.userInfo();
    this.setState({ isAuthenticated, user })
  };

  onSignOut = () => {
    this.setState({ isAuthenticated: false, user: null })
    storage.resetLocalStorage();
  }

  render() {
    return (
      <React.Fragment>
        <Router>
          <div id="app-container" style={{position: "absolute", width: "100%", height: "100%"}}>
            { this.state.isAuthenticated ? 
              <Header user={this.state.user} authenticator={auth} onSignOut={this.onSignOut} /> : null}
            <Switch>
              <AuthenticationSwitch {...this.props} isAuthenticated={this.state.isAuthenticated} 
              user={this.state.user} onSignIn={this.onSignIn} onSignOut={this.onSignOut} 
              authenticator={auth}>
                <Route path="/login" component={Login}/>
                <Route path="/jobs" component={Jobs} />
                <Route path="/dashboard" component={Dashboard} />
                <Route path="/adduser" render={props => <AddUser authenticator={auth} user={this.state.user} />}/>
                <Route path="/resetpassword" render={props => <ResetPassword authenticator={auth} user={this.state.user} />} />
                <Route path="/confirm" render={props => <Confirm authenticator={auth} />} />
                <Route path="/resetconfirm" render={props => <ResetConfirm authenticator={auth} />} />
              </AuthenticationSwitch>
            </Switch>
          </div>
        </Router>
      </React.Fragment>
    )
  }
}

export default App
