import React from "react"
import { Alert, Input, Button, Panel } from "@brightcove/studio-components";
import "../styles/resetpassword.scss";

class ResetPassword extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      email: props.user ? props.user.email : "",
      success: false
    };
  }
  
  onChangeFn = (field) => {
    return (value) => {
      this.setState({ [field]: value });
    }
  }

  onSubmit = () => {
    const { authenticator } = this.props;
    authenticator.forgotPassword(this.state.email, (err, result) => {
      if (err) {
        console.info(err);
        this.setState({ 
          success: false,
          error: err.message 
        });
      } else {
        this.setState({ 
          success: true,
          error: null 
        });
        console.info(result);
      }
    })
  }

  render() {
    const {email, error, success} = this.state;
    let canSubmit = (email && email.length > 0);
    
    return (
      <React.Fragment>
        <Panel className="resetpassword-panel">
          <div className="resetpassword-title">Reset Password</div>
          <Alert className="resetpassword-error" block type={ success ? "info" : "error"} 
            style={(success || error) ? {display:"block"} : {display:"none"}}>
            { success ? "Check your email for reset password instructions" : (error ? error : "Something's wrong")}
          </Alert>
          <Input className="resetpassword-field" label="Email" placeholder="Email" value={email} onChange={this.onChangeFn("email")} required/>
          <Button className="resetpassword-submit" type="submit" text="Reset" onClick={this.onSubmit} disabled={!canSubmit}/>
        </Panel>
      </React.Fragment>
    )
  }
}

export default ResetPassword
