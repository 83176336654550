import React from "react";
import { Panel, PanelTitleBar, IconButton } from "@brightcove/studio-components";
import DataPanel from "../DataPanel/DataPanel";
import "./VideoDetailPanel.scss";

class VideoDetailPanel extends React.Component<any, any> {  
  render() {
    const { video, dismissDetailPanel } = this.props;
    return (
      <Panel className="video-detail-panel">
        <PanelTitleBar className="video-detail-titlebar" title={<div className="video-detail-title">ASSET INFORMATION</div>}>
          <IconButton className="video-detail-close" name="cancel" onClick={() => dismissDetailPanel() } />
        </PanelTitleBar>
        <DataPanel title="VIDEO" data={video.getVideoPanelData()}/>
        <DataPanel title="SHOW" data={video.getShowData()}/>
        <DataPanel title="EPISODE" data={video.getEpisodeData()}/>
        <DataPanel title="IMAGES" type="Image" data={video.getImages()}/>
        <DataPanel title="RENDITION" type="Rendition" data={video.getRenditions()}/>
      </Panel>
    )
  }
};

export default VideoDetailPanel
