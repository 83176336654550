import React from "react"
import { withRouter } from "react-router-dom"
import axios from "axios";
import { Panel } from "@brightcove/studio-components";
import config from "../config";
import "../styles/jobs.scss";

class Jobs extends React.Component<any, any> {
  constructor(props) {
    super(props);
  }
  
  componentWillMount() {
    const {user, history} = this.props;
    const jobs = this.getJobs(user["custom:jobs"]);
    if (jobs.length === 1) {
      localStorage.setItem(config.localStorageKeys.JOB, jobs[0]);
      history.push("/dashboard");
    }
  }
  
  getJobs(jobs) {
    return jobs.split(",");
  }
  
  onClick(job) {
    const {history} = this.props;
    localStorage.setItem(config.localStorageKeys.JOB, job);
    history.push("/dashboard");
  }

  render() {
    const {user} = this.props;
    const jobs = this.getJobs(user["custom:jobs"]);
    
    return (
      <React.Fragment>
        <div className="job-background">
          {jobs &&
            <Panel className="job-panel">
              <ul>
                {jobs.map((job, index) => (
                  <li key={index} onClick={()=> {this.onClick(job)}}>{job}</li>
                ))}
              </ul>
            </Panel>
          }
        </div>
      </React.Fragment>
    )
  }
}

export default withRouter(Jobs)
