import React from "react"
import { Alert, Input, Button, Panel } from "@brightcove/studio-components";
import { Route, Switch, Redirect } from "react-router-dom";
import "../styles/login.scss";

class Login extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      success: false
    };
  }

  onChangeFn = (field) => {
    return (value) => {
      this.setState({ [field]: value });
    }
  }

  onSubmit = () => {
    const { authenticator, onSignIn } = this.props;
    const { email, password } = this.state;
    authenticator.authenticate(email, password, (result, error) => {
      if (error) {
        console.info(error);
        this.setState({ error: error.message });
      } else {
        onSignIn(true);
      }
    });
  }
  
  onKeyDown = (e) => {
    if (e.keyCode === 13) { // login when press enter
      this.onSubmit();
    }
  }

  render() {
    const {email, password, error, success} = this.state;
    var canSubmit = (email && email.length > 0) && (password && password.length > 0);

    return (
      <React.Fragment>
        <div onKeyDown={this.onKeyDown}>
          <Panel className="login-panel">
            <div className="login-title">Welcome!</div>
            <div className="login-subtitle">Please sign into the Ingest Dashboard with your existing login.</div>
            <Alert className="login-error" block type={ success ? "info" : "error"} 
              style={(success || error) ? {display:"block"} : {display:"none"}}>
              {error ? error : "Something's wrong"}
            </Alert>
            <Input className="login-field" label="Email" placeholder="Email" value={email} onChange={this.onChangeFn("email")} required/>
            <Input className="login-field" label="Password" placeholder="Password" type="password" value={password} onChange={this.onChangeFn("password")} required/>
            <Button className="login-submit" type="submit" text="Sign In" onClick={this.onSubmit} disabled={!canSubmit}/>
            <a id="login-resetpassword" href="/resetpassword">Reset password</a>
          </Panel>
        </div>
      </React.Fragment>
    )
  }
}

export default Login
