import React from "react"
import { Route, Switch, Redirect } from "react-router-dom"
import { withRouter, matchPath } from "react-router";
import Dashboard from "../screens/Dashboard";
import Jobs from "../screens/Jobs";
import ResetPassword from "../screens/ResetPassword";
import Confirm from "../screens/Confirm";
import ResetConfirm from "../screens/ResetConfirm";
import Login from "../screens/Login";
import config from "../config";
import _ from "lodash";

class NullRoute extends React.Component<any, any> {
  static defaultProps = {
    condition: true,
    location: { pathname: "" },
    path: "*"
  }

  componentDidMount() {
    this.forceUpdate();
  }

  componentDidUpdate() {
    const {condition, fn} = this.props;
    if(condition && this.pathMatches()) fn();
  }

  pathMatches = () => {
    const {path, exact, strict} = this.props;
    return matchPath(location.pathname, {path, exact, strict});
  }

  render() {
    return (this.props.condition && this.pathMatches() && <Route {...this.props} render={props => null} />)
  }
};

const RedirectToRoute: React.FunctionComponent<any> = (props) => {
  let redirectProps = _.pick(props, ["to", "push", "from", "exact", "strict"]);
  let routeProps = _.pick(props, ["match", "location", "history", "path", "component", "render", "exact", "strict", "sensitive"])
  let pathname = routeProps.path;

  return (
    <React.Fragment>
      {location.pathname != pathname && <Redirect {...redirectProps} to={pathname} />}
      <Route {...routeProps} />
    </React.Fragment>
  )
};

class AuthenticationSwitch extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      hasClientAuthenticationStatus: false,
      isClientAuthenticated: props.isAuthenticated
    };
  }

  componentDidUpdate(prevProps) {
    if(prevProps.isAuthenticated && !this.props.isAuthenticated) {
      this.setState({
        hasClientAuthenticationStatus: false,
        isClientAuthenticated: false
      })
    }
  }

  async getClientStatus() {
    const { authenticator } = this.props;
    let status = await authenticator.isAuthenticated();

    this.setState({
      hasClientAuthenticationStatus: true,
      isClientAuthenticated: status
    });
  }

  render() {
    const {authenticator, history, location, user, isAuthenticated, onSignIn, onSignOut, children} = this.props;
    const {hasClientAuthenticationStatus, isClientAuthenticated} = this.state;

    if (!hasClientAuthenticationStatus) {
      return (
        <NullRoute fn={() => {
          this.getClientStatus()
        }} />
      )
    } else if (!isAuthenticated) {
      if (authenticator.requiresLoginScreen) {
        if (isClientAuthenticated) {
          return (
            <NullRoute fn={() => {
              authenticator.renewSession(onSignIn);
            }} />
          )
        } else {
          switch (location.pathname) {
            case "/resetpassword":
              return (
                <RedirectToRoute path="/resetpassword" render={props => <ResetPassword authenticator={authenticator} user={user} />} />
              )
              break;
            case "/confirm":
              return (
                <RedirectToRoute path="/confirm" render={props => <Confirm authenticator={authenticator} />} />
              )
              break;
            case "/resetconfirm":
              return (
                <RedirectToRoute path="/resetconfirm" render={props => <ResetConfirm authenticator={authenticator} />} />
              )
              break;
            default:
              return <RedirectToRoute path="/login" exact render={props => <Login authenticator={authenticator} onSignIn={onSignIn} />} />
          }
        }
      } else {
        return (
          <NullRoute fn={() => {
            if(isClientAuthenticated) {
              authenticator.renewSession(onSignIn);
            }
            else if(/access_token|id_token|error/.test(history.location.hash)) {
              authenticator.handleAuthentication(onSignIn);
            }
            else {
              authenticator.login()
            }
          }} />
        )
      }
    } else {
      let job = localStorage.getItem(config.localStorageKeys.JOB);
      
      if (job && job !== "") {
        if (location.pathname === "/") {
          return (
            <Redirect from="/" to="/dashboard" />
          )
        } else {
          return (
            <Switch>
              <Redirect from="/login" to="/dashboard" />
              <Redirect from="/jobs" to="/dashboard" />
              <NullRoute path="/logout" exact fn={() => {
                authenticator.logout(onSignOut);
              }} />
              {children}
            </Switch>
          )
        }
      } else {
        return <RedirectToRoute path="/jobs" exact render={props => <Jobs user={user}/>}/>
      }
    }
  }
}

export default withRouter(AuthenticationSwitch)
