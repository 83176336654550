import React from "react"
import { Loader, Input, Icon, Button, Panel, Flex, FlexItem, Table, TableHead, TableRow, TableBody, TableCell, 
DropdownButton, DropdownMenu, DropdownMenuItem, CheckboxGroup, Checkbox } from "@brightcove/studio-components";
import NumberBoardBlock from "../components/NumberBoardBlock/NumberBoardBlock";
import SearchBar from "../components/SearchBar/SearchBar";
import VideoDetailPanel from "../components/VideoDetailPanel/VideoDetailPanel";
import "../styles/dashboard.scss";
import DataLoader from "../service/dataLoader";
import Video from "../models/video";
import config from "../config";
import _ from "lodash";

const totalRows = ["10", "20", "50"];
const totalColumns = [
  {name: "status", label: "Status"}, 
  {name: "contentId", label: "Content ID"}, 
  {name: "referenceId", label: "Reference ID"}, 
  {name: "batchId", label: "Batch ID"},
  {name: "timeStarted", label: "Time Started"}, 
  {name: "timeEnded", label: "Time Ended"},
  {name: "videoLength", label: "Video Length"}, 
  {name: "processingTime", label: "Processing Time"}, 
  {name: "showId", label: "Show ID"}, 
  {name: "uniqueAssetId", label: "Unique Asset ID"}
];

const defaultColumns = [
  {name: "index", label: ""}, 
  {name: "name", label: "Name"},
  {name: "contentId", label: "Content ID"}, 
  {name: "videoLength", label: "Video Length"},  
  {name: "processingTime", label: "Processing Time"}, 
  {name: "status", label: "Status"}
];

const dataLoader = new DataLoader();

class Dashboard extends React.Component<any, any> {
  mounted = false;
  columns;
  constructor(props) {
    super(props);
    this.columns = localStorage.getItem(config.localStorageKeys.COLUMNS) || "";
    if (this.columns) {
      this.columns = JSON.parse(this.columns);
    }
    this.state = {
      currentVideo: null,
      showingVideoDetail: false,
      loading: true,
      loadingMore: false,
      hasMore: false,
      videos: {},
      status: {
        complete: 0,
        started: 0,
        pending: 0,
        failed: 0
      },
      numRows: localStorage.getItem(config.localStorageKeys.NUM_ROWS) || "20",
      columns: this.columns || defaultColumns
    }
  }
  
  async componentWillMount() {
    await dataLoader.pullData(this.state.numRows, (data, finished) => {
      if (this.mounted) { // only update state when mounted.
        this.setState({
          videos: data.videoData,
          loading: false,
          hasMore: finished ? false : true,
          status: {
            complete: data.completeNum,
            started: data.inProgressNum,
            pending: data.pendingNum,
            failed: data.failedNum
          }
        })  
      }
    });
  }
  
  componentDidMount() {
    const appDiv = document.getElementById("app-container");
    if (appDiv) appDiv.className = "autoHeight";
    this.mounted = true;
  }
  
  componentWillUnmount() {
    const appDiv = document.getElementById("app-container");
    if (appDiv) appDiv.className = "";
    this.mounted = false;
    dataLoader.stopRefreshing();
  }
  
  async onRowSelected(numRows) {
    this.setState({ 
      numRows: numRows,
      loading: true
    });
    
    localStorage.setItem(config.localStorageKeys.NUM_ROWS, numRows);
    
    await dataLoader.fetchVideos(numRows, (data, finished) => {
      this.setState({
        videos: data.videoData,
        loading: false,
        hasMore: finished ? false : true,
        status: {
          complete: data.completeNum,
          started: data.inProgressNum,
          pending: data.pendingNum,
          failed: data.failedNum
        }
      });
    });
  }
  
  dismissDetailPanel() {
    this.setState({
      currentVideo: null,
      showingVideoDetail: false
    });
  }
  
  toggleDetailPanel(video) {
    if (this.state.showingVideoDetail && this.state.currentVideo.contentId === video.contentId) {
      this.setState({
        currentVideo: null,
        showingVideoDetail: false,
      });
    } else {
      this.setState({ 
        currentVideo: video,
        showingVideoDetail: true
      });
    }
  }
  
  searchVideo(searchKey) {
    const result = dataLoader.search(searchKey);
    this.setState({
      videos: result
    });
  }
  
  async loadMore() {
    this.setState({ loadingMore: true })
    await dataLoader.fetchNextBatch(this.state.numRows, (data, finished) => {
      if (data) {
        this.setState({
          videos: data.videoData,
          loadingMore: false,
          hasMore: finished ? false : true,
          status: {
            complete: data.completeNum,
            started: data.inProgressNum,
            pending: data.pendingNum,
            failed: data.failedNum
          }
        })  
      }
    });
  }

  render() {
    return (
      <React.Fragment>
        <Flex gap='none'>
        <FlexItem flex='1 0 auto'>
          <NumberBoardBlock data={{name:"Completed", value:this.state.status.complete, icon:config.statusIcons.complete}}/>
        </FlexItem>
        <FlexItem flex='1 0 auto'>
          <NumberBoardBlock data={{name:"Started", value:this.state.status.started, icon:config.statusIcons.started}}/>
        </FlexItem>
        <FlexItem flex='1 0 auto'>
          <NumberBoardBlock data={{name:"Pending", value:this.state.status.pending, icon:config.statusIcons.pending}}/>
        </FlexItem>
        <FlexItem flex='1 0 auto'>
          <NumberBoardBlock data={{name:"Failed", value:this.state.status.failed, icon:config.statusIcons.failed}}/>
        </FlexItem>
        </Flex>
        <div className="dashboard-separator">
          <DropdownMenu className="dashboard-filter-button" label={this.state.numRows + " Rows"}>
            {
              totalRows.map((item, index) => {
                return (
                  <DropdownMenuItem key={index} onClick={() => this.onRowSelected(item)}>
                    {item + " Rows"}
                  </DropdownMenuItem>
                )
              })
            }
          </DropdownMenu>
          <DropdownButton className="dashboard-filter-button" label='Columns'>
            {
              (dismiss) =>
                <Panel className="dashboard-filter-column">
                  <CheckboxGroup
                    label={<div className="dashboard-filter-label">Columns</div>}
                  > 
                  {
                    totalColumns.map((item, index) => {
                      return (
                        <Checkbox
                          key={index}
                          spanClassName="dashboard-filter-checkbox-span"
                          checked={_.find(this.state.columns, (col) => {
                            return item.name === col.name;
                          }) ? true : false}
                          onChange={(checked) => {
                            let columns = this.state.columns;
                            if (checked) {
                              columns.push(item)
                            } else {
                              _.remove(columns, (n) => {
                                return n["name"] === item.name
                              })
                            }
                            this.setState({ columns });
                            localStorage.setItem(config.localStorageKeys.COLUMNS, JSON.stringify(columns));
                          }}>
                          {item.label}
                        </Checkbox>
                      )
                    })
                  }
                  </CheckboxGroup>
                </Panel>
            }
          </DropdownButton>
          <Flex justifyContent="flex-end" style={{width:"100%"}}>
            <SearchBar className="dashboard-search" onSearch={(value) => this.searchVideo(value)}/>
          </Flex>
        </div>
        {this.state.loading ? <Loader /> : null}
        <div className="dashboard-data-block" style={{display: this.state.loading ? "none" : "block"}}>
          <Table className="dashboard-table">
            <TableHead>
              <TableRow>
                {this.state.columns.map((item, index) => {
                  return <TableCell key={index} header>{item.label}</TableCell>;
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.entries(this.state.videos).map((kvPair, i) => {
                let key = kvPair[0];
                let value = kvPair[1];
                let currentVideo = new Video(value);
                return (
                  <TableRow key={key}>
                    {this.state.columns.map((item, index) => {
                      if (item.name === "index") {
                        return (
                          <TableCell key={i + index + ""}>
                            <Button className="info-button" onClick={() => this.toggleDetailPanel(currentVideo)} 
                            small>
                            <i className="fas fa-info"></i>
                            </Button>
                            {(i+1) + ""}
                          </TableCell>
                         )
                      }
                      if (item.name === "status") {
                        let icon = config.statusIcons[currentVideo.status];
                        return (
                          <TableCell
                          className="icon-cell"
                          key={i + index + ""} style={{ color: icon.color }}>
                          <i className={icon.iconId} style={{ color: icon.color }}></i>
                          {currentVideo.status === "started" ? currentVideo.percentage : ""}
                          </TableCell>
                        )
                      }
                      return (
                        <TableCell className={item.name === "contentId" ? "text-bold" : ""} 
                        key={i + index + ""}>
                        {currentVideo[item.name] ? currentVideo[item.name] : "--"}
                        </TableCell>
                      )
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </div>
        <div className="dashboard-footer">
          <Button spinner={this.state.loadingMore} disabled={!this.state.hasMore} className="dashboard-loadmore" onClick={() => this.loadMore()}>
            <Icon name="caret_down" />
          </Button>
        </div>
        {this.state.showingVideoDetail ? 
          <VideoDetailPanel video={this.state.currentVideo} dismissDetailPanel={() => this.dismissDetailPanel()}/>
          : null
        }
      </React.Fragment>
    )
  }
}

export default Dashboard
