import React from "react";
import {Tabs, TabList, SmallTab, Flex} from "@brightcove/studio-components";
import "./NumberBoardBlock.scss";

class NumberBoardBlock extends React.Component<any, any> {
  constructor(props) {
    super(props);
  }
  
  render() {
    const { data: {name, value, icon: { color, iconId }} } = this.props;
    return (
      <div className="number-board-block">
        <div className="number-board-block-number" style={{ color: color }}>{value}</div>
        <div className="number-board-block-title"><i className={iconId} style={{ color: color }}></i>{name}</div>
      </div>
    )
  }
};

export default NumberBoardBlock
