import React from "react"
import { withRouter } from "react-router";
import { Alert, Input, Button, Panel } from "@brightcove/studio-components";
import "../styles/confirm.scss";

class Confirm extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      code: "",
      success: false
    };
  }
  
  componentWillMount() {
    const { location: { search } } = this.props;
    let urlParams;
    if (search) {
      urlParams = this.getJsonFromSearch(search);
      this.setState({
        code: urlParams["code"] ? urlParams["code"] : ""
      })
    }
  }
  
  getJsonFromSearch(search) {
    let query = search.substr(1);
    let result = {};
    query.split("&").forEach((part) => {
      let item = part.split("=");
      result[item[0]] = decodeURIComponent(item[1]);
    });
    return result;
  }
  
  onChangeFn = (field) => {
    return (value) => {
      this.setState({ [field]: value });
    }
  }

  onSubmit = () => {
    const { authenticator, history } = this.props;
    authenticator.confirmRegistration(this.state.email, this.state.code, (err, result) => {
      if (err) {
        console.error(err);
        this.setState({ 
          success: false,
          error: err.message 
        });
      } else {
        this.setState({ 
          success: true,
          error: null 
        });
        history.push("/login");
      }
    })
  }

  render() {
    const {email, code, error, success} = this.state;
    let canSubmit = ((email && email.length > 0) && (code && code.length > 0));
    
    return (
      <React.Fragment>
        <Panel className="confirm-panel">
          <div className="confirm-title">Confirm Account</div>
          <Alert className="confirm-error" block type={ success ? "info" : "error"} 
            style={(success || error) ? {display:"block"} : {display:"none"}}>
            { success ? "Your account is confirmed!" : (error ? error : "Something's wrong")}
          </Alert>
          <Input className="confirm-field" label="Email" placeholder="Email" value={email} onChange={this.onChangeFn("email")} required/>
          <Input className="confirm-field" label="Confirmation Code" placeholder="Confirmation Code" value={code} onChange={this.onChangeFn("code")} required/>
          <Button className="confirm-submit" type="submit" text="Confirm" onClick={this.onSubmit} disabled={!canSubmit}/>
        </Panel>
      </React.Fragment>
    )
  }
}

export default withRouter(Confirm)
