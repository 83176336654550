import React from "react";
import {SearchInput} from "@brightcove/studio-components";
import "./SearchBar.scss";

class SearchBar extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      search: ""
    }
  }
  
  render() {
    const { className, onSearch } = this.props;
    return (
      <SearchInput
        className={className}
        value={this.state.search}
        placeholder="Search videos..."
        onChange={(value) => {
          this.setState({search: value})
          onSearch(value);
        }} />
    )
  }
};

export default SearchBar
