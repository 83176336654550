import React from "react";
import {Tabs, TabList, SmallTab, Flex, DropdownMenu, DropdownMenuItem} from "@brightcove/studio-components";
import {withRouter} from "react-router-dom";
import "./Header.scss";
import config from "../../config";

class Header extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      selectedTab: "/dashboard"
    }
  }

  componentDidMount() {
    this.getTab();
  }

  componentDidUpdate(prevProps) {
    if(prevProps.location.pathname != this.props.location.pathname) {
      this.getTab()
    }
  }

  getTab() {
    let tabIds = ["/dashboard", "/adduser", "/resetpassword"];
    let path = (/\/[a-zA-Z]*/g.exec(this.props.location.pathname) || [])[0];
    let selectedTab = "/";

    for(let id of tabIds) {
      if(path == id)
        selectedTab = id;
    }
    if(this.state.selectedTab != selectedTab) {
      this.setState({selectedTab});
    }
  }

  onSelect(id) {
    const {history, authenticator, onSignOut} = this.props;
    if (id === "/username") return;
    this.setState({
      selectedTab: id
    });
    if (id === "/logout") {
      authenticator.logout(onSignOut);
      return;
    }
    history.push(id);
  }
  
  onJobSelected(job) {
    const currentJob = localStorage.getItem(config.localStorageKeys.JOB);
    if (currentJob !== job) {
      localStorage.setItem(config.localStorageKeys.JOB, job);
      window.location.reload();
    }
  }

  render() {
    const {selectedTab} = this.state;
    const {user} = this.props;
    const jobs = user["custom:jobs"].split(",");

    return (
      <div className="header">
        <div className="header-logo"><img src={config.bannerImage}/></div>
          <React.Fragment>
            <Flex justifyContent="flex-end" style={{width:"100%"}}>
              <Tabs selectedId={selectedTab} onSelect={(id) => this.onSelect(id)} className="header-tabs">
                <TabList>
                  <SmallTab className="header-tab" tabId="/dashboard">HOME</SmallTab>
                  <SmallTab className="header-tab" tabId="/adduser">ADD USER</SmallTab>
                  <SmallTab className="header-tab" tabId="/resetpassword">CHANGE PASSWORD</SmallTab>
                  <SmallTab className="header-tab" tabId="/logout">LOG OUT</SmallTab>
                </TabList>
              </Tabs>
              <DropdownMenu className="header-user-menu" label={<div className="header-user-label">{user.email}</div>} buttonProps={{className: "header-menu-button"}}>
                {jobs.map((job, index) => (
                  <DropdownMenuItem key={index} onClick={() => this.onJobSelected(job)}>{job}</DropdownMenuItem>
                ))}
              </DropdownMenu>
            </Flex>
          </React.Fragment>
      </div>
    )
  }
};

export default withRouter(Header);
