import axios from "axios"
import config from "../config";

export default class DataLoader {
  apiUrl; 
  job;
  data;
  lastUpdate;
  intervalId;
  nextBatch;
  refreshNumRows;
  customFields;
  testInt = 0;
  
  constructor() {
    this.data = {
      videos: [],
      lastUpdate: {},
      showRef: false,
      completeNum: 0,
      failedNum: 0,
      pendingNum: 0,
      inProgressNum: 0
    }
  }
  
  pullData(numRows, done) {
    this.job = localStorage.getItem(config.localStorageKeys.JOB);
    this.apiUrl = config.apiUrl;
    return this.fetchVideos(numRows, done);
  }
  
  // fetch videos based on numRows and then sets refresh() to run on an interval
  fetchVideos(numRows, done) {
    let baseUrl = this.apiUrl.replace('{jobname}', this.job)
    
    baseUrl += "?max_count=" + numRows;
    
    return axios({
      method: "GET",
      url: baseUrl
    }).then((response) => {
      let d = response.data;
      let t = this;
      this.data.videos = d['video_list']
      this.data.videoData = d.video_data;
      this.data.completeNum = d.summary.counts.complete
      this.data.failedNum = d.summary.counts.failed
      this.data.pendingNum = d.summary.counts.pending
      this.data.inProgressNum = d.summary.counts.started
      this.lastUpdate = d.updated_at.$date
      
      this.nextBatch = response.data.next_batch.$date;
      this.refreshNumRows = numRows;
      
      if (this.intervalId) {
        clearInterval(this.intervalId)
        this.intervalId = null
      }
      this.intervalId = setInterval(function () {
          t.refresh(done);
      }, config.FETCH_INTERVAL)
      
      if (this.nextBatch) {
        done(this.data);
      } else {
        done(this.data, true);
      }
    }).catch((error) => {
        console.error('ERROR IN INIT ', error)
        setTimeout(() => this.fetchVideos(numRows, done), config.RETRY_TIMEOUT)
    })
  }
  
  // Continues to get next_batch until batch is empty.
  fetchNextBatch(numRows, done) {
      let baseUrl = this.apiUrl.replace('{jobname}', this.job);
      
      baseUrl = baseUrl + "?max_count=" + numRows + "&next_batch=" + this.nextBatch;
      
      axios({
        method: "GET",
        url: baseUrl
      }).then((response) => {
        this.lastUpdate = response.data.updated_at.$date
        this.data.completeNum = response.data.summary.counts.complete
        this.data.failedNum = response.data.summary.counts.failed
        this.data.pendingNum = response.data.summary.counts.pending
        this.data.inProgressNum = response.data.summary.counts.started
        if (response.data.video_list.length > 0) {
          for (let x in response.data.video_list) {
            let temp = response.data.video_list[x]
            if (this.data.videos.indexOf(temp) !== -1) {
              this.update(temp, response.data.video_data)
            } else {
              this.data.videos.push(temp)
              this.data.videoData[temp] = response.data.video_data[temp]
            }
          }
        }
        this.testInt += 1
        
        this.nextBatch = response.data.next_batch.$date;
        this.refreshNumRows = parseInt(this.refreshNumRows) + parseInt(numRows) + "";
        
        if (this.nextBatch) {
          done(this.data);
        } else {
          done(this.data, true);
        }
        
      }).catch((error) => {
        console.log('ERROR IN FETCH NEXT BATCH ', error)
      })
    }
  
    // Get all videos since lastUpdate
    refresh(done) {
      let baseUrl = this.apiUrl.replace('{jobname}', this.job);
      
      if (this.lastUpdate) {
        baseUrl = baseUrl + "?data_since=" + this.lastUpdate + "&max_count=" + this.refreshNumRows;
      } else {
        baseUrl = baseUrl + "?max_count=" + this.refreshNumRows;
      }
      
      axios({
        method: "GET",
        url: baseUrl
      }).then((response) => {
        if (response.data.updated_at.$date) this.lastUpdate = response.data.updated_at.$date
        this.data.completeNum = response.data.summary.counts.complete
        this.data.failedNum = response.data.summary.counts.failed
        this.data.pendingNum = response.data.summary.counts.pending
        this.data.inProgressNum = response.data.summary.counts.started
        if (response.data.video_list.length > 0) {
          for (var x in response.data.video_list) {
            var temp = response.data.video_list[x]
            if (this.data.videos.indexOf(temp) !== -1) {
              this.update(temp, response.data.video_data)
            } else {
              this.data.videos.unshift(temp)
              this.data.videoData[temp] = response.data.video_data[temp]
            }
          }
        }
        
        if (this.nextBatch) {
          done(this.data);
        } else {
          done(this.data, true);
        }
        
        this.testInt += 1
      }).catch((error) => {
        console.log('ERROR IN REFRESH ', error)
      })
    }
    
    stopRefreshing() {
      clearInterval(this.intervalId);
      this.intervalId = null;
    }
    
    // Updates video data
    update(id, json) {
      for (let i in this.data.videos) {
        if (i === id) {
          let index = this.data.videos.indexOf(i)
          if (index !== -1) {
            this.data.videos.splice(index, 1)
            this.data.videos.unshift(i)
          }
        }
      }
      for (let x in Object.keys(this.data.videoData)) {
        var temp = Object.keys(this.data.videoData)[x]
        if (temp === id) {
          this.data.videoData[temp] = json[temp]
        }
      }
    }
    
    // Search function
    search(searchKey) {
      let results = {}
      let arr = this.data.videos;
      for (let i = 0; i < arr.length; i++) {
        let id = arr[i]
        let item = this.data.videoData[arr[i]]
        let name = item.video_data.name != null ? item.video_data.name.toLowerCase() : ""
        let refid = item.video_data.reference_id
        let batchid = item.batch_id
        let status = item.status
        if (refid === undefined || refid === null) {
          refid = ""
        }
        if (batchid === undefined || batchid === null) {
          batchid = ""
        }

        if (name.includes(searchKey) || refid.includes(searchKey) || batchid.includes(searchKey) 
        || status.includes(searchKey) || id.includes(searchKey)) {
          results[arr[i]] = item;
        }
      }
      
      return results
    }
}