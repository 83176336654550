import React from "react"
import { Alert, Input, Button, Panel, CheckboxGroup, Checkbox} from "@brightcove/studio-components";
import "../styles/adduser.scss";
import _ from "lodash";

class AddUser extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      jobs: [],
      admin: false,
      success: false
    };
  }
  
  getJobs(jobs) {
    return jobs.split(",");
  }
  
  onChangeFn = (field) => {
    return (value) => {
      this.setState({ [field]: value });
    }
  }

  onSubmit = () => {
    const { authenticator } = this.props;
    authenticator.signup(
      this.state.email, 
      this.state.email, 
      "", 
      this.state.admin ? "true" : "false", 
      this.state.jobs.toString(), 
      (err, result) => {
        if (err) {
          if (err.message.includes("Value at 'username' failed to satisfy constraint:") === true) {
            err.message = 'Invalid email address'
          }
          this.setState({ 
            success: false,
            error: err.message
          });
          console.error(err)
          console.log('ERROR')
        } else {
          this.setState({ 
            success: true,
            error: null 
          });
          console.log('NO ERROR')
        }
      })
  }

  render() {
    const {email, error, success} = this.state;
    const {user} = this.props;
    const jobs = this.getJobs(user["custom:jobs"]);
    let canSubmit = (email && email.length && this.state.jobs.length > 0);
    
    return (
      <React.Fragment>
        <div className="adduser-background">
          <Panel className="adduser-panel" title="USER INFO*">
            <Alert className="adduser-error" block type={ success ? "info" : "error"} 
              style={(success || error) ? {display:"block"} : {display:"none"}}>
              { success ? "User successfully added" : (error ? error : "Something's wrong")}
            </Alert>
            <Input className="adduser-field" label="Email" placeholder="Email" value={email} onChange={this.onChangeFn("email")} required/>
            <CheckboxGroup
              label="Select Jobs"
              required
            > 
              {jobs.map((job, i) =>
                  <Checkbox
                    key={i}
                    checked={this.state.jobs.indexOf(job) > -1}
                    onChange={(checked) => {
                      let jobs = this.state.jobs;
                      if (checked) {
                        jobs.push(job)
                      } else {
                        _.remove(jobs, (n) => n === job)
                      }
                      this.setState({ jobs });
                    }}>
                    {job}
                  </Checkbox>
              )}
            </CheckboxGroup>
            <CheckboxGroup
              label="Permissions"
            > 
            <Checkbox
              checked={this.state.admin}
              onChange={(admin) => this.setState({ admin })}>
              Make Admin?
            </Checkbox>
            </CheckboxGroup>
            <Button className="adduser-submit" type="submit" text="Sign Up" onClick={this.onSubmit} disabled={!canSubmit}/>
          </Panel>
        </div>
      </React.Fragment>
    )
  }
}

export default AddUser