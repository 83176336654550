import config from "../config";
var AWS = require('aws-sdk')
var AWSCognito = require('amazon-cognito-identity-js')

export default class CognitoAuthenticator {
  options;
  userSession;
  userPool;
  requiresLoginScreen = true;

  constructor() {
    this.options = config
    this.userSession = null
    this.userPool = new AWSCognito.CognitoUserPool({
      UserPoolId: config.cognito.UserPoolId,
      ClientId: config.cognito.ClientId
    })
    AWS.config.region = config.cognito.region;
    AWS.Config.credentials = new AWS.CognitoIdentityCredentials({
      IdentityPoolId: this.options.cognito.UserPoolId
    })
  }

  isAuthenticated() {
    return new Promise<boolean>(resolve => {
      let cognitoUser = this.getCurrentUser()
      if(cognitoUser) {
        cognitoUser.getSession((err, session) => {
          if(err) {
            console.log(err);
            return resolve(false);
          }
          this.userSession = session;
          resolve(true);
        })
      }
      else {
        resolve(false);
      }
    });
  }

  authenticate(username, password, done) {
    let authenticationDetails = new AWSCognito.AuthenticationDetails({
      Username: username,
      Password: password
    });
    let cognitoUser = new AWSCognito.CognitoUser({
      Username: username,
      Pool: this.userPool
    });
    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: (result) => {
        var logins = {}
        this.userSession = result;
        logins['cognito-idp.' + this.options.cognito.region + '.amazonaws.com/' + this.options.cognito.UserPoolId] = result.getIdToken().getJwtToken()

        AWS.Config.credentials = new AWS.CognitoIdentityCredentials({
          IdentityPoolId: this.options.cognito.UserPoolId,
          Logins: logins
        });

        done(result);
      },
      onFailure: (err) => {
        done(false, err);
      }
    })
  }
  
  confirmRegistration(username, code, cb) {
    let cognitoUser = new AWSCognito.CognitoUser({
      Username: username,
      Pool: this.userPool
    })
    cognitoUser.confirmRegistration(code, true, cb)
  }
  
  confirmPassword(username, code, password, cb) {
    let cognitoUser = new AWSCognito.CognitoUser({
      Username: username,
      Pool: this.userPool
    })

    cognitoUser.confirmPassword(code, password, {
      onSuccess: (result) => {
        cb(null, result)
      },
      onFailure: function (err) {
        cb(err)
      }
    })
  }

  renewSession(done) {
    let refreshToken = this.userSession.getRefreshToken();
    let cognitoUser = this.getCurrentUser();

    if(!cognitoUser) {
      done(false);
      return;
    }

    cognitoUser.refreshSession(refreshToken, (err, session) => {
      if(err) {
        done(false)
      }
      else {
        this.userSession = session;
        done(true);
      }
    });
  }

  logout(done) {
    let user = this.getCurrentUser();
    if(user) {
      user.signOut();
      if(done) done();
    }
  }
  
  forgotPassword (username, cb) {
    let cognitoUser = new AWSCognito.CognitoUser({
      Username: username,
      Pool: this.userPool
    })

    cognitoUser.forgotPassword({
      onSuccess: (result) => {
        cb(null, result)
      },
      onFailure: function (err) {
        cb(err)
      },
      inputVerificationCode () {
        cb()
      }
    })
  }
  
  signup (username, email, pass, admin, jobs, cb) {
    pass = 'Password123'
    username = email
    let attributeList = [
      new AWSCognito.CognitoUserAttribute({
        Name: 'email',
        Value: email
      }),
      new AWSCognito.CognitoUserAttribute({
        Name: 'custom:admin',
        Value: admin.toString()
      }),
      new AWSCognito.CognitoUserAttribute({
        Name: 'custom:jobs',
        Value: jobs
      })
    ]

    this.userPool.signUp(username, pass, attributeList, null, cb)
  }


  getIdToken() {
    return this.userSession.getIdToken().getJwtToken()
  }

  userInfo() {
    return new Promise(resolve => {
      let cognitoUser = this.getCurrentUser()
      if(cognitoUser) {
        cognitoUser.getSession((err, session) => {
          if (err) {
            console.log(err);
            return resolve({});
          }
          this.userSession = session;
          cognitoUser.getUserAttributes((err, result) => {
            if (err) {
              console.log(err);
              return resolve({});
            }
            let info = {};
            for(let field of result) {
              info[field.getName()] = field.getValue();
            }
            resolve(info);
          });
        });
      }
      else {
        resolve({});
      }
    })
  }

  getCurrentUser() {
    return this.userPool.getCurrentUser()
  }
};
